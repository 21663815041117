export const environment = {
	COMPANY_NAME: 'evollo',
	APP_NAME: 'ID',
	production: true,
	apiUrl: 'https://ailos-account.api.evollo.cloud',
	qualityApiUrl: 'https://ailos-quality.api.evollo.cloud',
	recaptchaId: '6Le_4tQmAAAAALwMgkZF8dyNpcwY9KG1-a8_se6m',
	webClientId: '1epsrqv4bbe1k63l5590ob8sag',
	apps: {
		id: 'https://ailos.id.evollo.com.br',
		admin: 'https://ailos.admin.evollo.com.br',
		quality: 'https://ailos.quality.evollo.com.br',
		cognito: 'id-ailos.auth.us-east-1.amazoncognito.com'
	},
	features: {
		enableSignUp: false,
		enableAzureSignUp: true,
		recaptchaEnabled: true,
		enableSupport: false,
		enableFaq: false
	},
};
