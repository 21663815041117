import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/service';
import { ActivatedRoute } from '@angular/router';


@Component({
	selector: 'app-logout',
	template: ``
})
export class LogoutComponent implements OnInit {

	constructor(public userService: UserService,
	            private activatedRoute: ActivatedRoute) {
	}

	public ngOnInit() {
		this.activatedRoute.queryParams.subscribe(params => {
			let token: string = localStorage.getItem('accessToken') as string;
			if(params.token) {
				token = params.token;
			}

			this.userService.logout(token);
		});
	}
}
