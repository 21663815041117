<app-login-page-wrapper>
	<form class="signup-form" [formGroup]="signupForm" (ngSubmit)="onSubmit()" slot="form" *ngIf="!registerDone">
        <img class="mb-3 w-25" src="/assets/images/evollo_logo_vertical.png"/>
        <h5>Comece gratuitamente hoje</h5>
        <div class="mb-2"><span>ou </span><a [routerLink]="['/']" class="text-primary" style="cursor: pointer;">entre em sua conta</a></div>
        <div class="form-group pb-3 mb-0">
            <label class="required">Nome</label>
            <input type="text" formControlName="name" placeholder="Digite seu nome" class="form-control" (keydown)="preventSpace($event)"/>
        </div>
		<div class="form-group pb-3 mb-0">
			<label class="required">Sobrenome</label>
			<input type="text" formControlName="lastName" placeholder="Digite seu sobrenome" class="form-control"/>
		</div>
        <div class="form-group pb-3 mb-0">
            <label class="required">Email</label>
            <input type="email" formControlName="email" placeholder="email@company.com" class="form-control"/>
        </div>
        <div class="form-group pb-3 mb-0">
            <label class="required">Senha</label>
            <div class="input-group" [ngClass]="{'valid' : confirmPassword?.valid }">
                <input [type]="showPassword ? 'text' : 'password'"
                       formControlName="password"
                       class="form-control"
                       (keyup)="changeShowPasswordButtonPosition()"
                       (blur)="changeShowPasswordButtonPosition()"
                       placeholder="Digite sua senha"/>
                <div [class]="'input-group-append ' + passwordButtonPositionClass">
                    <i (click)="showPassword = !showPassword" class="fas" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                </div>
            </div>
            <span class="text-muted">Pelo menos 8 caracteres, com, pelo menos, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial.</span>
        </div>
        <div class="form-group pb-4 mb-0">
            <label class="required">Confirmar senha</label>
            <div class="input-group" [ngClass]="{'valid' : confirmPassword?.valid }">
                <input [type]="showConfirmPassword ? 'text' : 'password'"
                       formControlName="confirmPassword"
                       placeholder="Digite sua senha"
                       (keyup)="changeShowConfirmPasswordButtonPosition()"
                       (blur)="changeShowConfirmPasswordButtonPosition()"
                       class="form-control"/>
                <div [class]="'input-group-append ' + passwordConfirmButtonPositionClass">
                    <i (click)="showConfirmPassword = !showConfirmPassword" class="fas" [ngClass]="showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                </div>
            </div>
            <span class="text-danger" *ngIf="confirmPassword?.dirty && confirmPassword?.hasError('notSame')">As senhas não coincidem.</span>
        </div>
		<div class="custom-control mb-2">
			<input type="checkbox" class="custom-control-input" id="terms" formControlName="terms">
			<label class="">Li e concordo com os <a (click)="openTerms($event)">Termos de Uso</a> e <a (click)="openTerms($event)">Política de Privacidade</a> </label>
			<span class="text-danger" *ngIf="terms?.dirty && terms?.hasError('needAccept')">É obrigatório aceitar os termos para criar sua conta.</span>
		</div>
        <button type="submit" [disabled]="signupForm.invalid || isLoading" class="btn btn-block btn-primary">
	        <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
	        Cadastre-se
        </button>
    </form>

	<div class="register-done" *ngIf="registerDone" slot="form">
		<div class="">

			<h5 class="text-muted">Excelente!</h5>

			<div class="my-3">
				<i class="far fa-check-circle text-success"></i>
			</div>

			<div class="">
				<h4>Agora, verifique o seu endereço de email</h4>
				<span class="">Uma vez verificado, você terá acesso a Eva InsightsHub.</span>
			</div>

			<a class="text-primary" routerLink="/" style="cursor: pointer;">
				<i class="fas fa-chevron-left mr-2" style="font-size: 16px;"></i>
				Voltar para tela inicial
			</a>
		</div>

		<!-- <div class="mt-3">
			<span class="text-muted">Não recebeu o email? Verifique sua caixa de spam ou <a href="#">Reenviar email</a></span>
		</div> -->
	</div>
</app-login-page-wrapper>
